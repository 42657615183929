<template>
  <div>
    <div class="text-2xl font-bold">Notifications</div>

    <div
      v-for="group in selfGroupProfilesWithDirtyFlag"
      :key="group.group.id"
      class="bg-white my-4"
    >
      <div class="sm:flex sm:flex-row pt-5 px-5 pb-2 justify-between flex flex-col">
        <div class="text-xl font-bold sm:pr-6 flex">
          {{ group.group.name
          }}<StarIcon class="h-4 w-4 my-auto ml-2 pb-1" v-if="group.isAdmin"></StarIcon>
        </div>
        <div class="flex">
          <div class="my-auto mr-2">
            Email Notifications:
            <span class="font-bold">{{ group.notificationsEnabled == true ? 'ON' : 'OFF' }}</span>
          </div>
          <div>
            <toggle-button
              class="mx-auto"
              :width="50"
              :height="30"
              :font-size="18"
              v-model="group.notificationsEnabled"
              :sync="true"
            />
          </div>
        </div>
      </div>
      <div class="px-5 pb-8">
            <p class="flex justify-between items-center gap-2">
              Receive group level notifications via email
            </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StarIcon from '@/assets/icon_star.svg';

export default {
  name: 'NotificationSettings',
  components: {
    StarIcon,
  },
  computed: {
    ...mapGetters(['selfGroupProfiles', 'user', 'groupFeatureFlags']),
  },
  data() {
    return {
      breadcrumbs: [
        {
          label: 'Profile',
          link: 'profile',
        },
      ],

      selfGroupProfilesWithDirtyFlag: [],
      anyDirty: false,
    };
  },

  methods: {
    ...mapActions(['fetchSelfGroupProfiles', 'setNotificationsEnabled']),
    save() {
      this.selfGroupProfilesWithDirtyFlag
        .filter((item) => {
          return item.originalNotificationsEnabled !== item.notificationsEnabled;
        })
        .forEach((dirtyItem) => {
          const params = {
            groupSlug: dirtyItem.group.slug,
            notificationsEnabled: dirtyItem.notificationsEnabled,
            userId: this.user.user.id,
          };
          this.setNotificationsEnabled(params).then((res) => {
            if (res.success) {
              dirtyItem.originalNotificationsEnabled = dirtyItem.notificationsEnabled;
              this.$emit('dirty', false);
            }
          });
        });
    },

    cancel() {
      this.selfGroupProfilesWithDirtyFlag
        .filter((item) => {
          return item.originalNotificationsEnabled !== item.notificationsEnabled;
        })
        .forEach((dirtyItem) => {
          dirtyItem.notificationsEnabled = dirtyItem.originalNotificationsEnabled;
        });
    },
  },
  created() {
    this.fetchSelfGroupProfiles().then(() => {
      this.selfGroupProfilesWithDirtyFlag = this.selfGroupProfiles.map((item) => {
        item.originalNotificationsEnabled = item.notificationsEnabled;
        return item;
      });
    });
  },
  watch: {
    selfGroupProfilesWithDirtyFlag: {
      handler() {
        if (
          this.selfGroupProfilesWithDirtyFlag.find((item) => {
            return item.originalNotificationsEnabled !== item.notificationsEnabled;
          }) !== undefined
        ) {
          this.anyDirty = true;
          this.$emit('dirty', true);
        } else {
          this.$emit('dirty', false);
          this.anyDirty = false;
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped></style>
