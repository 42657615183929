<template>
  <nav>
    <ol class="breadcrumbs min-w-max" :class="{ white: isWhite }">
      <li class="breadcrumb-item" v-if="scheme === SCHEMES.primary">
        <HomeIconSimple @click="redirect('home', -1)" class="cursor-pointer" />
      </li>
      <li v-for="(crumb, index) of crumbs" :key="index" class="breadcrumb-item">
        <ChevronRightIcon
          class="w-2 h-3 mx-3"
          v-if="scheme === SCHEMES.primary || (scheme === SCHEMES.secondary && index !== 0)"
        />
        <span class="link" @click="redirect(crumb.link, index)">
          {{ crumb.label }}
        </span>
      </li>
    </ol>
  </nav>
</template>

<script>
import HomeIconSimple from '@/assets/icon_home_simple.svg';
import ChevronRightIcon from '@/assets/icon_chevron_right.svg';

const SCHEMES = {
  primary: 'primary',
  secondary: 'secondary',
};

export default {
  name: 'Breadcrumbs',
  components: {
    HomeIconSimple,
    ChevronRightIcon,
  },
  props: {
    scheme: {
      type: String,
      default: SCHEMES.primary,
    },
    crumbs: {
      type: Array,
      required: true,
    },
    isWhite: {
      type: Boolean,
    },
  },
  data() {
    return {
      SCHEMES,
    };
  },
  methods: {
    isLast(index) {
      return index === this.crumbs.length - 1;
    },
    redirect(link, i) {
      if (this.scheme === SCHEMES.secondary && !this.isLast(i)) {
        this.$emit('action');
      }
      if (!this.isLast(i)) {
        this.$router.push({ name: link });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  @apply flex items-center;

  .breadcrumb-item {
    @apply flex items-center;

    .link {
      @apply cursor-pointer text-sm font-normal;
    }
  }

  &.white {
    .link {
      color: #ffffff;
    }
    svg path {
      stroke: #ffffff;
    }
  }
}
</style>
