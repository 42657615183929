<template>
  <button
    type="button"
    :disabled="loading || disabled"
    @click.stop="click"
    class="inline-block leading-relaxed relative text-center appearance-none rounded cursor-pointer transform focus:outline-none active:scale-95 transition duration-200 ease"
    :class="[
      { primaryFullSize },
      { primary: type === 'primary' },
      { secondary: type === 'secondary' && !disabled },
      {
        'border-orange-400':
          type === 'secondary' && (!colorCustom || isColorDefault()) && !disabled,
      },
      { 'border-none': borderNone },
      { text: type === 'text' && !disabled },
      { disabled },
      { loading },
      { active: activeState && activeState === 'active' },
      { inactive: activeState && activeState === 'inactive' },
      { 'red-background': permanentAction },
      { 'px-6 py-3': !compact },
      { 'px-3 py-0': compact },
      {
        'bg-primary sm:hover:shadow-primary sm:focus:shadow-primary':
          colorCustom && !isColorDefault() && type === 'primary',
      },
      {
        'bg-group-color-orange sm:hover:shadow-buttonPrimary sm:focus:shadow-buttonPrimary':
          (!colorCustom || isColorDefault()) && type === 'primary',
      },
      type === 'secondary' ? customClass : '',
    ]"
  >
    <div class="flex items-center justify-center pointer-events-none w-full h-full">
      <span v-if="hasIcon && !loading" class="mr-2">
        <slot name="icon"></slot>
      </span>
      <div v-if="loading" class="flex">
        <SpinningCircleAnimation class="w-5 h-5"></SpinningCircleAnimation>
      </div>
      <span v-else class="button-content">
        <slot><!-- Button Text --></slot>
      </span>
    </div>
  </button>
</template>

<script>
import SpinningCircleAnimation from '@/assets/icon_spinning_circle.svg';
import { DEFAULT_COLOR } from '@/components/constants/groupColors';

import { mapGetters } from 'vuex';

export default {
  name: 'Button',
  components: {
    SpinningCircleAnimation,
  },
  props: {
    /**
     * Determines what type of button this should be visually
     */
    type: {
      type: String,
      validator: (type) => ['primary', 'secondary', 'text'].indexOf(type) !== -1,
      required: true,
    },
    /**
     * Whether or not the button should be disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /*
     * Determines whether the button is reacting to an async event.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /*
     * add specific styling for button when active
     */
    activeState: {
      type: String,
      required: false,
    },
    customClass: {
      type: String,
      default: 'bg-transparent',
    },
    /*
     * permanent actions should be styled in red
     */
    permanentAction: {
      type: Boolean,
    },
    compact: {
      type: Boolean,
      default: () => false,
    },
    primaryFullSize: {
      type: Boolean,
      default: () => true,
    },
    colorCustom: {
      type: Boolean,
      default: () => false,
    },
    borderNone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultColor: DEFAULT_COLOR,
    };
  },
  computed: {
    ...mapGetters(['group']),
    hasIcon() {
      return this.$slots.icon;
    },
  },
  methods: {
    click() {
      this.$emit('onClick');
    },
    isColorDefault() {
      return this.group.primaryColor === this.defaultColor;
    },
  },
};
</script>

<style lang="scss" scoped>
.primary {
  @apply relative z-0 border border-transparent;

  &.primaryFullSize {
    @apply w-full;
  }

  &::after {
    @apply absolute -z-1 rounded opacity-100 transition-opacity ease-out duration-200;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    content: '';
  }

  &.disabled {
    box-shadow: none;
  }

  &:active {
    &::after {
      @apply opacity-0;
    }

    .button-content {
      @apply text-white text-sm font-bold;
    }

    &.white-border {
      border-color: white !important;
    }
  }

  .button-content {
    @apply text-white text-sm font-bold;
  }
}

.red-background {
  @apply bg-red-600 border-red-600;
}

.secondary {
  @apply relative text-sm font-semibold rounded border;
}

.text {
  @apply bg-transparent text-0_8xl font-semibold rounded border border-gray-400 px-8;

  &.semi-transparent {
    @apply border-opacity-40;
  }
}

.text.active {
  @apply bg-gray-300 text-primary px-5 mx-2;
}

.text.inactive {
  @apply bg-transparent text-gray-600 px-5 mx-2;
}

.disabled:not(.loading) {
  @apply cursor-default bg-gray-300 text-gray-500 border border-transparent;

  &:hover {
    @apply bg-gray-300 text-gray-500;
  }
}

.loading {
  @apply border-0 cursor-default;
}

@keyframes opacityPulse {
  0%,
  100% {
    @apply opacity-100;
  }
  60% {
    @apply opacity-0;
  }
}
</style>
