var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"inline-block leading-relaxed relative text-center appearance-none rounded cursor-pointer transform focus:outline-none active:scale-95 transition duration-200 ease",class:[
    { primaryFullSize: _vm.primaryFullSize },
    { primary: _vm.type === 'primary' },
    { secondary: _vm.type === 'secondary' && !_vm.disabled },
    {
      'border-orange-400':
        _vm.type === 'secondary' && (!_vm.colorCustom || _vm.isColorDefault()) && !_vm.disabled,
    },
    { 'border-none': _vm.borderNone },
    { text: _vm.type === 'text' && !_vm.disabled },
    { disabled: _vm.disabled },
    { loading: _vm.loading },
    { active: _vm.activeState && _vm.activeState === 'active' },
    { inactive: _vm.activeState && _vm.activeState === 'inactive' },
    { 'red-background': _vm.permanentAction },
    { 'px-6 py-3': !_vm.compact },
    { 'px-3 py-0': _vm.compact },
    {
      'bg-primary sm:hover:shadow-primary sm:focus:shadow-primary':
        _vm.colorCustom && !_vm.isColorDefault() && _vm.type === 'primary',
    },
    {
      'bg-group-color-orange sm:hover:shadow-buttonPrimary sm:focus:shadow-buttonPrimary':
        (!_vm.colorCustom || _vm.isColorDefault()) && _vm.type === 'primary',
    },
    _vm.type === 'secondary' ? _vm.customClass : '',
  ],attrs:{"type":"button","disabled":_vm.loading || _vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.click.apply(null, arguments)}}},[_c('div',{staticClass:"flex items-center justify-center pointer-events-none w-full h-full"},[(_vm.hasIcon && !_vm.loading)?_c('span',{staticClass:"mr-2"},[_vm._t("icon")],2):_vm._e(),(_vm.loading)?_c('div',{staticClass:"flex"},[_c('SpinningCircleAnimation',{staticClass:"w-5 h-5"})],1):_c('span',{staticClass:"button-content"},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }