<template>
     <div
       class="px-8 2xl:max-w-screen-2xl   2xl:mx-auto min-w-full md:min-w-0">
       <div class="sticky top-[69px] flex flex-col sm:flex-row justify-between mb-12 pb-8 md:pt-16 pt-10 bg-gray-100 z-50 border-b border-gray-400 text-base relative">
        <div>
            <Breadcrumbs class="mb-3" :crumbs="breadcrumbs"></Breadcrumbs>
            <div>
                <div><h1>Settings</h1></div>
            </div>
        </div>
        <div class="md:mt-10 mt-2">

          <Button
            class="cancel-btn sm:w-auto mr-4"
            type="text"
            @onClick="onCancel()"
          >
            Cancel
          </Button>
          <Button
            class="save-btn  sm:w-auto  h-56px"
            type="primary"
            :color-custom="true"
            :primary-full-size="false"
            :disabled="!anyDirtyData"
            @onClick="saveSettings()"
          >
            Save
          </Button>
        </div>

       </div>

       <div class="flex">
        <div class="mr-15 hidden">
            <a href="#" class="text-2xl" :class="{'font-bold': selectedTab == 'notifications'}">Notifications</a>
        </div>
            <div class="inline-block h-[500px] min-h-[1em] w-0.5 self-stretch bg-gray-900 mr-15 hidden "></div>
            <div v-if="selectedTab == 'notifications'">
            <NotificationSettings @dirty="handleDirty" ref="notificationSettings"></NotificationSettings>
       </div>

       </div>

    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Button from '@/components/Button.vue';
import NotificationSettings from '@/components/NotificationSettings.vue';

export default {
  name: 'UserSettings',
  components: {
    Breadcrumbs,
    Button,
    NotificationSettings,
  },
  computed: {
    ...mapGetters(['user']),

  },

  data() {
    return {
      breadcrumbs: [
        {
          label: 'Settings',
          link: 'settings',
        },
      ],
      selectedTab: 'notifications',
      anyDirtyData: false,
    };
  },

  methods: {
    ...mapActions([
      '',
    ]),
    handleDirty(isDirty) {
      this.anyDirtyData = isDirty;
    },
    saveSettings() {
      this.$refs.notificationSettings.save();
    },
    onCancel() {
      this.$refs.notificationSettings.cancel();
    },
  },
};
</script>
  <style lang="scss" scoped>

  </style>
